<template>
  <list-container-widget
    id="kahoot"
    :title="widgetName"
    :app-id="99"
    stretch
    class="d-flex"
  >
    <iframe :src="kahootUrl" style="border:none;height: 100%; border-radius: 5px;"></iframe>
  </list-container-widget>
</template>

<script>

import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';

export default {
  name: 'Kahoot',
  components: { ListContainerWidget },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  computed: {
    kahootUrl() {
      if(this.item?.customizations != null){
        return this.item?.customizations['Kahoot-link'];
      }
      return 'https://kahoot.it/';
    },
    item() {
      return this.$store.getters.item('integrations', 'kahoot');
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        customName: 'integrations',
        page: 1,
        requestConfig: {
          orderByDate: -1,
          type: 'integrations',
        },
      });
      this.isLoading = false;
    },
  },
};
</script>

